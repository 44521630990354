@layer base {
  .main {
  width: 100%;
  max-width: 100vw;
  padding: var(--spacer-800) 0;
}

.wrapper {
  margin: 0;
  overflow: hidden;
  text-align: center;
}

.button {
  margin-top: var(--spacer-400);
}

.logo {
  filter: grayscale(1) contrast(1.5) opacity(0.5);
  width: auto !important;
  height: var(--spacer-800);
  margin: 0 var(--spacer-400);
}

.title {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 0 var(--Layout-column-margin-size);
}

@media (--medium-viewport) {
  .title {
    max-width: var(--medium-breakpoint);
  }

  .button {
    margin-top: var(--spacer-600);
  }
}

@media (--large-viewport) {
  .title {
    max-width: var(--large-breakpoint);
  }
}

@media (--major-viewport) {
  .title {
    max-width: var(--major-breakpoint);
  }
}

}