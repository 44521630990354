@layer modules, ui, base;
@layer base {
  .Partners_main__ItI1h {
  width: 100%;
  max-width: 100vw;
  padding: var(--spacer-800) 0;
}

.Partners_wrapper__L0BMH {
  margin: 0;
  overflow: hidden;
  text-align: center;
}

.Partners_button__1bKeO {
  margin-top: var(--spacer-400);
}

.Partners_logo__pLkar {
  filter: grayscale(1) contrast(1.5) opacity(0.5);
  width: auto !important;
  height: var(--spacer-800);
  margin: 0 var(--spacer-400);
}

.Partners_title__fj_VT {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 0 var(--Layout-column-margin-size);
}

@media (min-width: 48rem) {
  .Partners_title__fj_VT {
    max-width: var(--medium-breakpoint);
  }

  .Partners_button__1bKeO {
    margin-top: var(--spacer-600);
  }
}

@media (min-width: 64rem) {
  .Partners_title__fj_VT {
    max-width: var(--large-breakpoint);
  }
}

@media (min-width: 80rem) {
  .Partners_title__fj_VT {
    max-width: var(--major-breakpoint);
  }
}

}
